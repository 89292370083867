import { useState } from "react";
import styles from "../../styles/admin/Login.module.css";
import { adminAPI } from "../../api/api";

const Login = ({ setIsLogged }) => {
  const [error, setError] = useState("");

  return (
    <div className={styles.container}>
      <form
        className={styles.form}
        onSubmit={async (e) => {
          e.preventDefault();
          try {
            await adminAPI.login({
              login: e.currentTarget[0].value,
              password: e.currentTarget[1].value,
            });
            setIsLogged(true);
          } catch (err) {
            setError(err?.response?.data?.message || "No server response");
          }
        }}
      >
        <label htmlFor="email" className={styles.label}>
          Login
        </label>
        <input
          id="email"
          type="text"
          placeholder="Enter your email"
          autoComplete="off"
          className={styles.input}
        />
        <label htmlFor="password" className={styles.label}>
          Password
        </label>
        <input
          id="password"
          type="password"
          placeholder="Enter your password"
          className={styles.input}
        />
        <div className={styles.buttonContainer}>
          <button type="submit" className={styles.button}>
            Sign in
          </button>
          <button
            type="button"
            className={styles.button}
            onClick={() => window.history.back()}
          >
            Back
          </button>
        </div>
      </form>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default Login;
