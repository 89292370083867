import { useEffect, useState } from "react";
import s from "../styles/general/icons.module.css";
import styles from "../styles/Filter.module.css";
import { publicAPI } from "../api/api";
import { useLocation } from "react-router-dom";

const Filter = ({
  minPrice = "",
  maxPrice = "",
  filterValue = false,
  setMinPrice = () => {},
  setMaxPrice = () => {},
  setFilterValue = () => {},
  setIsOpen = () => {},
}) => {
  const location = useLocation().pathname.split("/");
  const _id = location[location.length - 1];
  const [availableCount, setAvailableCount] = useState(null);
  const [from, setFrom] = useState(minPrice);
  const [to, setTo] = useState(maxPrice);
  const [isAvailable, setIsAvailable] = useState(filterValue);
  const [initFrom, setInitFrom] = useState(0);
  const [initTo, setInitTo] = useState(0);

  const getRange = async () => {
    try {
      const res = await publicAPI.getRange(_id);
      setFrom(res.data.minPrice || "");
      setTo(res.data.maxPrice || "");
      setIsAvailable(false);
      setMinPrice(res.data.minPrice || "");
      setMaxPrice(res.data.maxPrice || "");
      setFilterValue(false);
      setInitFrom(res.data.minPrice || "");
      setInitTo(res.data.maxPrice || "");
      setAvailableCount(res.data.availableCount || 0);
    } catch (err) {
      console.log(err);
    }
  };

  const getAvailable = async () => {
    try {
      const res = await publicAPI.getRange(
        _id,
        `minPrice=${minPrice}&maxPrice=${maxPrice}`
      );
      setAvailableCount(res.data.availableCount || 0);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (location[location.length - 2] === "search_results") return;
    getRange();
  }, [_id]);

  useEffect(() => {
    if (location[location.length - 2] === "search_results") return;
    getAvailable();
  }, [minPrice, maxPrice]);

  return (
    <div className={styles.filterContainer}>
      <div className={styles.header}>
        <h6 className={styles.headerText}>
          <span className={styles.highlight}>Ф</span>ільтри
        </h6>
      </div>
      <div className={styles.filterContent}>
        <div className={styles.sectionTitle}>
          <span>Ціна</span>
        </div>
        <div className={styles.inputContainer}>
          <input
            onInput={(e) => {
              const input = e.target.value;
              if (Number.isInteger(+input) || input === "") setFrom(input);
            }}
            className={styles.priceInput}
            placeholder="від ..."
            value={from}
          ></input>
          <input
            onInput={(e) => {
              const input = e.target.value;
              if (Number.isInteger(+input) || input === "") setTo(input);
            }}
            className={styles.priceInput}
            placeholder="до ..."
            value={to}
          ></input>
          <button
            onClick={() => {
              if (+to < +from || to > initTo || from < initFrom) {
                setTo(+initTo);
                setFrom(+initFrom);
                setMinPrice(+initFrom);
                setMaxPrice(+initTo);
                return;
              }
              setMinPrice(+from);
              setMaxPrice(+to);
              setFilterValue(isAvailable);
              setIsOpen(false);
            }}
            className={`${s.content20} ${styles.filterButton}`}
          ></button>
        </div>

        <div>
          <div style={{ marginBottom: "10px" }} className={styles.sectionTitle}>
            <span>Наявність</span>
          </div>
          <div className={styles.availabilityContainer}>
            <input
              className={styles.checkbox}
              type="checkbox"
              checked={isAvailable}
              onClick={() => {
                setIsAvailable(!isAvailable);
              }}
            ></input>{" "}
            <span>В наявності </span>
            <span className={styles.availableCount}>{availableCount}</span>
          </div>
        </div>

        <div
          onClick={() => {
            setMinPrice(+initFrom);
            setMaxPrice(+initTo);
            setFilterValue(false);
            setTo(+initTo);
            setFrom(+initFrom);
            setIsAvailable(false);
            setIsOpen(false);
          }}
          className={styles.resetFilter}
        >
          <span>Скинути всі фільтри</span>
        </div>
      </div>
    </div>
  );
};

export default Filter;
