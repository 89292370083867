import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import s from "../../styles/general/icons.module.css";
import s2 from "../../styles/general/overlay.module.css";
import styles from "../../styles/admin/ProductDetailsAdmin.module.css";
import Overlay from "./overlay";
import DescriptionAdmin from "./description_admin";
import SpecsAdmin from "./specs_admin";
import OrderInfoAdmin from "./order_info_admin";
import { adminAPI, publicAPI } from "../../api/api";
import HOST from "../../env";

const ProductDetailsAdmin = () => {
  const [element, setElement] = useState("description");
  const location = useLocation().pathname.split("/");
  const _id = location[location.length - 1];
  const [editOn, setEditOn] = useState(false);
  const [product, setProduct] = useState({});
  const [text, setText] = useState(product[element]);
  const [isInit, setIsInit] = useState(true);
  const textAreaRef = useRef(null);

  const getProduct = async () => {
    try {
      const res = await publicAPI.getProducts(_id);
      setProduct(res.data.products[0]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    setText(product[element]);
  }, [product, element]);

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      const textarea = event.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      textarea.value =
        textarea.value.substring(0, start) +
        "\t" +
        textarea.value.substring(end);
      textarea.selectionEnd = end + 1;
      setText(textarea.value);
    }
  };

  useEffect(() => {
    if (textAreaRef.current) {
      const savedScrollPosition = document.documentElement.scrollTop;
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
      window.scrollTo(0, savedScrollPosition);
    }
  }, [text, editOn]);

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <div className={styles.imageContainer}>
          {product?.pictures?.length &&
            product.pictures.map((pictures, index1) => {
              pictures = pictures.split("\n");
              return (
                <div className={styles.pictureBlock} key={`col-${index1}`}>
                  {pictures.map((picture, index2) => (
                    <div
                      className={`${s2.container} ${styles.pictureBlock}`}
                      style={{
                        height: pictures.length > 1 ? "49%" : "100%",
                      }}
                      key={`img-${index2}`}
                    >
                      <div
                        className={`${s2.child} ${styles.pictureItem}`}
                        style={{
                          backgroundImage: `url(${HOST}/${picture})`,
                          
                        }}
                      ></div>
                      <Overlay
                        type={pictures.length === 1 ? "Split" : "Join"}
                        indexes={[index1, index2]}
                        product={product}
                        _id={_id}
                        getProduct={getProduct}
                      />
                    </div>
                  ))}
                </div>
              );
            })}
        </div>

        <div className={styles.tabContainer}>
          {["description", "specs", "orderInfo"].map((tab) => (
            <div
              key={tab}
              className={`${styles.tab} ${
                isInit && tab === "description" && "borderTop"
              }`}
              onClick={(e) => {
                e.currentTarget.parentNode.childNodes.forEach((child) =>
                  child.classList.remove("borderTop")
                );
                e.currentTarget.classList.add("borderTop");
                if (isInit) setIsInit(false);
                setElement(tab);
                setEditOn(false);
              }}
            >
              <i
                className={
                  tab === "description"
                    ? s.content16
                    : tab === "specs"
                    ? s.content17
                    : s.content18
                }
                style={{ marginBottom: "5px" }}
              ></i>
              <span className={styles.tabLabel}>
                {tab === "description"
                  ? "Опис"
                  : tab === "specs"
                  ? "Характеристики"
                  : "Інформація про замовлення"}
              </span>
            </div>
          ))}
        </div>
        {editOn ? (
          <>
            <div className={styles.buttons}>
              <button
                onClick={async () => {
                  try {
                    const update =
                      element === "description"
                        ? { description: text }
                        : element === "specs"
                        ? { specs: text }
                        : { orderInfo: text };
                    await adminAPI.editProductDetails(_id, update);
                    getProduct();
                    setEditOn(false);
                  } catch (err) {
                    console.log(err);
                  }
                }}
                className={styles.button}
              >
                Зберегти
              </button>
              <button
                onClick={() => setEditOn(false)}
                className={styles.button}
              >
                Скасувати
              </button>
            </div>
            <textarea
              value={text}
              ref={textAreaRef}
              onChange={(e) => setText(e.target.value)}
              onKeyDown={handleKeyDown}
              className={styles.textarea}
            />
          </>
        ) : (
          <>
            {element === "description" ? (
              <DescriptionAdmin
                data={product?.description}
                productDescription={true}
                setEditOn={setEditOn}
              />
            ) : element === "specs" ? (
              <SpecsAdmin data={product?.specs} setEditOn={setEditOn} />
            ) : (
              <OrderInfoAdmin
                orderInfo={product?.orderInfo}
                setEditOn={setEditOn}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProductDetailsAdmin;
