import React, { useEffect, useRef, useState } from "react";
import s from "../../styles/general/icons.module.css";
import styles from "../../styles/admin/DeliveryAdmin.module.css";
import Caption from "../caption";
import { adminAPI } from "../../api/api";

const DeliveryAdmin = ({
  delivery = {
    deliveryWays: "",
    paymentWays: "",
    warranty: "",
  },
  editOn = false,
  setEditOn = () => {},
  getInfo = () => {},
}) => {
  const [text, setText] = useState(delivery?.deliveryWays);
  const [text2, setText2] = useState(delivery?.paymentWays);
  const [text3, setText3] = useState(delivery?.warranty);

  useEffect(() => {
    setText(delivery?.deliveryWays);
    setText2(delivery?.paymentWays);
    setText3(delivery?.warranty);
  }, [delivery]);

  const textAreaRef = useRef(null);
  const textAreaRef2 = useRef(null);
  const textAreaRef3 = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  }, [text, editOn]);

  useEffect(() => {
    if (textAreaRef2.current) {
      textAreaRef2.current.style.height = "auto";
      textAreaRef2.current.style.height =
        textAreaRef2.current.scrollHeight + "px";
    }
  }, [text2, editOn]);

  useEffect(() => {
    if (textAreaRef3.current) {
      textAreaRef3.current.style.height = "auto";
      textAreaRef3.current.style.height =
        textAreaRef3.current.scrollHeight + "px";
    }
  }, [text3, editOn]);

  if (editOn)
    return (
      <div className={styles.editContainer}>
        <textarea
          value={text}
          ref={textAreaRef}
          onChange={(e) => setText(e.target.value)}
          className={styles.textArea}
        />
        <textarea
          value={text2}
          ref={textAreaRef2}
          onChange={(e) => setText2(e.target.value)}
          className={styles.textArea}
        />
        <textarea
          value={text3}
          ref={textAreaRef3}
          onChange={(e) => setText3(e.target.value)}
          className={styles.textArea}
        />
        <div className={styles.buttonContainer}>
          <button
            onClick={async () => {
              await adminAPI.editInfo({
                delivery: {
                  deliveryWays: text,
                  paymentWays: text2,
                  warranty: text3,
                },
              });
              getInfo();
              setEditOn(false);
            }}
            className={styles.saveButton}
          >
            Зберегти
          </button>
          <button
            onClick={() => setEditOn(false)}
            className={styles.cancelButton}
          >
            Скасувати
          </button>
        </div>
      </div>
    );

  return (
    <div className={styles.container}>
      <Caption text={"Умови доставки та оплати"} />
      <div className={styles.section}>
        <span className={`${s["content" + 13]} ${styles.sectionTitle}`}>
          Способи доставки
        </span>
        {delivery?.deliveryWays?.split("\n\n").map((text, index) => (
          <div key={index} className={styles.textBlock}>
            {text.split("\n").map((substring, i) => (
              <span key={i}>{substring}</span>
            ))}
          </div>
        ))}
      </div>
      <div className={styles.section}>
        <span className={`${s["content" + 14]} ${styles.sectionTitle}`}>
          Способи оплати
        </span>
        {delivery?.paymentWays?.split("\n\n").map((text, index) => (
          <div key={index} className={styles.textBlock}>
            {text.split("\n").map((substring, i) => (
              <span key={i}>{substring}</span>
            ))}
          </div>
        ))}
      </div>
      <div className={styles.section}>
        <span className={`${s["content" + 13]} ${styles.sectionTitle}`}>
          Гарантія
        </span>
        {delivery?.warranty?.split("\n\n").map((text, index) => (
          <div key={index} className={styles.textBlock}>
            {text.split("\n").map((substring, i) => (
              <span key={i}>{substring}</span>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeliveryAdmin;
