import { useEffect, useState } from "react";
import { adminAPI } from "../../api/api";
import styles from "../../styles/admin/ScheduleAdmin.module.css";

const ScheduleAdmin = ({
  schedule = {},
  editOn = false,
  setEditOn = () => {},
  getInfo = () => {},
}) => {
  const [Schedule, setSchedule] = useState(schedule);

  useEffect(() => {
    setSchedule(schedule);
  }, [schedule]);

  if (editOn)
    return (
      <div className={styles.container}>
        <div className={styles.container}>
          {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(
            (day, index) => (
              <div key={index} className={styles.inputGroup}>
                <label>
                  {day === "Monday"
                    ? "Понеділок"
                    : day === "Tuesday"
                    ? "Вівторок"
                    : day === "Wednesday"
                    ? "Середа"
                    : day === "Thursday"
                    ? "Четвер"
                    : day === "Friday"
                    ? "П'ятниця"
                    : day === "Saturday"
                    ? "Субота"
                    : "Неділя"}
                </label>
                <input
                  onChange={(e) =>
                    setSchedule({ ...Schedule, [day]: e.target.value })
                  }
                  value={Schedule[day] || ""}
                />
              </div>
            )
          )}
        </div>
        <div className={styles.buttonGroup}>
          <button
            onClick={async () => {
              await adminAPI.editInfo({
                schedule: Schedule,
              });
              getInfo();
              setEditOn(false);
            }}
            className={styles.button}
          >
            Зберегти
          </button>
          <button
            onClick={() => setEditOn(false)}
            className={styles.button}
          >
            Скасувати
          </button>
        </div>
      </div>
    );

  return (
    <div className={styles.schedule}>
      <div className={styles.scheduleHeader}>
        <h6>
          <span>Г</span>рафік роботи
        </h6>
      </div>
      <ul className={styles.scheduleList}>
        {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(
          (day, index) => (
            <li key={index} className={styles.scheduleItem}>
              <span className={styles.scheduleDay}>
                {day === "Monday"
                  ? "Понеділок"
                  : day === "Tuesday"
                  ? "Вівторок"
                  : day === "Wednesday"
                  ? "Середа"
                  : day === "Thursday"
                  ? "Четвер"
                  : day === "Friday"
                  ? "П'ятниця"
                  : day === "Saturday"
                  ? "Субота"
                  : "Неділя"}
              </span>
              <span>{Schedule[day] || ""}</span>
            </li>
          )
        )}
      </ul>
    </div>
  );
};

export default ScheduleAdmin;
