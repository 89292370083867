import { adminAPI } from "../../api/api";
import styles from "../../styles/admin/Overlay.module.css";

const Overlay = ({
  type = "Split",
  indexes = [0, 0],
  product = {},
  _id = "",
  getProduct = () => {},
}) => {
  const handleDrop = async (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const newFileName = `${Number(new Date())}_${files[0].name}`;
    const updatedFile = new File([files[0]], newFileName, {
      type: files[0].type,
    });

    if (/^image\/(jpeg|png|gif|webp|bmp|svg\+xml)$/.test(files[0]?.type)) {
      let unlink = "";
      product.pictures[indexes[0]] = product.pictures[indexes[0]]
        .split("\n")
        .map((url, i) => {
          if (i === indexes[1]) {
            unlink = url;
            return `images/products/${newFileName}`;
          } else return url;
        })
        .join("\n");

      const formData = new FormData();
      product.pictures.forEach((picture, index) => {
        formData.append(`pictures[${index}]`, picture);
      });
      formData.append("unlink", unlink);
      formData.append("file", updatedFile);

      try {
        await adminAPI.editProductDetails(_id, formData);
        getProduct();
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleClick = async () => {
    const unlink = product.pictures[indexes[0]].split("\n")[+!indexes[1]];
    if (type === "Split") {
      product.pictures[indexes[0]] += "\n";
    } else if (type === "Join") {
      product.pictures[indexes[0]] =
        product.pictures[indexes[0]].split("\n")[indexes[1]];
    }

    const formData = new FormData();
    product.pictures.forEach((picture, index) => {
      formData.append(`pictures[${index}]`, picture);
    });
    formData.append("unlink", unlink);

    try {
      await adminAPI.editProductDetails(_id, formData);
      getProduct();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      className={styles.overlay}
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      <button className={styles.button} onClick={handleClick}>
        {type}
      </button>
      <span className={styles.dragLabel}>Drag & Drop</span>
    </div>
  );
};

export default Overlay;
