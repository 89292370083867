import React from "react";
import styles from "../../styles/admin/ConfirmDeleteModal.module.css";

const ConfirmDeleteModal = ({
  isOpen = false,
  setIsOpen = () => {},
  onConfirm = () => {},
  itemName = "товар/категорію",
}) => {
  if (!isOpen) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <h3 className={styles.modalHeader}>Підтвердження видалення</h3>
        <p className={styles.modalText}>
          Ви дійсно хочете видалити {itemName}?
        </p>
        <div className={styles.buttonContainer}>
          <button
            className={`${styles.button} ${styles.deleteButton}`}
            onClick={() => {
              onConfirm();
              setIsOpen(false);
            }}
          >
            Видалити
          </button>
          <button
            className={`${styles.button} ${styles.cancelButton}`}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Скасувати
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteModal;
