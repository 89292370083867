import React from "react";
import Caption from "../caption";
import s from "../../styles/general/overlay.module.css";
import styles from "../../styles/admin/DescriptionAdmin.module.css";

const DescriptionAdmin = ({
  data = "",
  captionText = "",
  productDescription = false,
  setEditOn = () => {},
  deleteOn = false,
  deleteDescription = async () => {},
  getCategory = () => {},
}) => {
  return (
    <div>
      {captionText && !productDescription && (
        <Caption text={captionText}></Caption>
      )}
      {data !== "" ? (
        <div className={`${s.container} ${styles.relativeContainer}`}>
          <div className={`${s.child} ${styles.whiteBackground}`}>
            {data.split("\n\n").map((text, index) => {
              return (
                <div
                  className={productDescription ? styles.productMargin : styles.defaultMargin}
                  key={index}
                >
                  {text.split("\n").map((substring, i) => (
                    <span
                      key={i}
                      className={
                        productDescription && index === 0
                          ? styles.boldText
                          : styles.regularText
                      }
                    >
                      {substring}
                    </span>
                  ))}
                </div>
              );
            })}
          </div>
          <div className={styles.overlay}>
            <button
              onClick={() => setEditOn(true)}
              className={styles.editButton}
            >
              Edit
            </button>
            {deleteOn && (
              <button
                onClick={async () => {
                  await deleteDescription();
                  getCategory();
                }}
                className={`${styles.editButton} ${styles.deleteButton}`}
              >
                Del
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className={`${s.container} ${styles.relativeContainer} ${styles.emptyContainer}`}>
          <div className={`${s.child} ${styles.whiteBackground}`}></div>
          <div className={styles.overlay}>
            <button
              onClick={() => setEditOn(true)}
              className={styles.editButton}
            >
              Add
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DescriptionAdmin;
