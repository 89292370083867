import React from "react";
import styles from "../../styles/admin/FilterAdmin.module.css";

const FilterAdmin = ({
  setFilter = () => {},
  setFilterValue = () => {},
  setCurrentPage = () => {},
  selected = "",
  options = [
    { text: "Всі", selected: "" },
    { text: "В наявності", selected: "isAvailable_true" },
    { text: "Немає в наявності", selected: "isAvailable_false" },
    { text: "Під замовлення", selected: "toOrder.on_true" },
    { text: "Рекомендовано", selected: "recommended_true" },
    { text: "Знижка", selected: "discountPrice_exists" }, 
    { text: "Оптові ціни", selected: "wholesalePrices_exists" },
  ],
}) => {
  return (
    <div className={styles.container}>
      <span className={styles.label}>Сортування: </span>
      <select
        onChange={(e) => {
          const value = e.target.value.split("_");
          setFilter(value[0]);
          setFilterValue(value[1]);
          setCurrentPage(1);
        }}
        className={styles.select}
      >
        {options.map((option) => (
          <option
            key={option.selected}
            selected={selected === option.selected}
            value={option.selected}
          >
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FilterAdmin;
