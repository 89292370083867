import React, { useEffect, useState } from "react";
import s from "../../styles/general/icons.module.css";
import styles from "../../styles/admin/FeedbacksPageAdmin.module.css";
import { adminAPI } from "../../api/api";
import ConfirmDeleteModal from "./confirm_delete_modal";
import Pagination from "../pagination";

const FeedbacksPageAdmin = () => {
  const [comments, setComments] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [onConfirm, setOnConfirm] = useState(() => {});
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const getComments = async () => {
    try {
      const res = await adminAPI.getComments(
        `pageSize=${pageSize}&currentPage=${currentPage}`
      );
      setComments(res.data.comments);
      setTotalCount(res.data.totalCount);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getComments();
  }, [currentPage]);

  return (
    <div className={styles.container}>
      <Pagination
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <ConfirmDeleteModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onConfirm={onConfirm}
      />
      <div className={styles.feedbackList}>
        {comments.map((comment) => (
          <Feedback
            {...comment}
            getComments={getComments}
            setIsOpen={setIsOpen}
            setOnConfirm={setOnConfirm}
            key={comment._id}
          />
        ))}
      </div>
    </div>
  );
};

const Feedback = ({
  _id,
  customerName = "",
  starCount = 0,
  phone = "",
  date = "",
  date2 = "",
  feedback = "",
  vendorComment = "",
  approved = false,
  getComments = () => {},
  setIsOpen = () => {},
  setOnConfirm = () => {},
}) => {
  const [isCommenting, setIsCommenting] = useState(false);
  const [VendorComment, setVendorComment] = useState(vendorComment);
  const starIndexes = [1, 2, 3, 4, 5];

  return (
    <div className={styles.feedbackCard}>
      {!isCommenting ? (
        <div className={styles.backgroundWrapper}>
          <div className={styles.feedbackHeader}>
            <div className={styles.infoBlock}>
              <span className={s.content21}></span>
              <span>{customerName}</span>
            </div>
            <div className={styles.infoBlock}>
              <span>
                {
                  [
                    "Жахливо",
                    "Погано",
                    "Недуже",
                    "Добре",
                    "Відмінно",
                    "Бездоганно",
                  ][starCount]
                }
              </span>
              <div>
                {starIndexes.map((i) => (
                  <StarIcon
                    key={i}
                    fill={i <= starCount ? "yellow" : "white"}
                  />
                ))}
              </div>
            </div>
            <div className={styles.infoBlock}>
              <span className={s.content22}></span>
              <span>{date}</span>
            </div>
            <div className={styles.infoBlock}>
              <span className={s.content2}></span>
              <span>{phone}</span>
            </div>
          </div>
          <div className={styles.feedbackBody}>
            <div className={styles.feedbackContent}>{feedback}</div>
            {date2 && vendorComment && (
              <div className={styles.vendorComment}>
                <div className={styles.vendorCommentHeader}>
                  <span>Коментар продавця</span>
                  <div className={styles.infoBlock}>
                    <span className={s.content22}></span>
                    <span>{date2}</span>
                  </div>
                </div>
                <span className={styles.vendorCommentBody}>{vendorComment}</span>
              </div>
            )}
          </div>
        </div>
      ) : (
        <textarea
          onChange={(e) => setVendorComment(e.target.value)}
          rows={6}
          style={{ width: "50%" }}
          value={VendorComment}
        />
      )}
      <div className={styles.actions}>
        <button
          className={`${styles.button} ${styles.publish}`}
          onClick={async () => {
            try {
              await adminAPI.changeComment({ _id, approved: !approved });
              getComments();
            } catch (e) {
              console.error(e);
            }
          }}
        >
          {approved ? "Приховати" : "Опублікувати"}
        </button>
        {isCommenting ? (
          <button
            className={`${styles.button} ${styles.publish}`}
            onClick={async () => {
              setIsCommenting(false);
              try {
                await adminAPI.changeComment({
                  _id,
                  vendorComment: VendorComment,
                  date2: VendorComment
                    ? new Date().toLocaleString().split(",")[0]
                    : "",
                });
                getComments();
              } catch (e) {
                console.error(e);
              }
            }}
          >
            Зберегти
          </button>
        ) : (
          <button
            className={`${styles.button} ${styles.publish}`}
            onClick={() => setIsCommenting(true)}
          >
            Коментувати
          </button>
        )}
        <button
          className={`${styles.button} ${styles.delete}`}
          onClick={() => {
            setIsOpen(true);
            setOnConfirm(() => async () => {
              try {
                await adminAPI.changeComment({ _id, remove: true });
                getComments();
              } catch (err) {
                console.error(err);
              }
            });
          }}
        >
          Видалити
        </button>
      </div>
    </div>
  );
};

const StarIcon = ({ fill = "white" }) => (
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      stroke="black"
      strokeWidth="2"
      d="M11.985,18.136 L5.187,23.000 L7.536,14.988 L1.000,9.562 L8.950,9.493 L11.985,1.000 L14.936,9.562 L23.000,9.562 L16.433,15.040 L18.783,23.000 L11.985,18.136 Z"
    />
  </svg>
);

export default FeedbacksPageAdmin;
