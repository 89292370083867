import React, { useState } from "react";
import s from "../../styles/general/overlay.module.css";
import styles from "../../styles/admin/Overlay2.module.css";

const Overlay2 = ({ children }) => {
  const [editOn, setEditOn] = useState(false);

  if (editOn)
    return (
      <>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, { editOn, setEditOn })
        )}
      </>
    );

  return (
    <div className={`${s.container} ${styles.container}`}>
      <div className={`${s.child} ${styles.child}`}>
        {React.Children.map(children, (child) =>
          React.cloneElement(child, { editOn, setEditOn })
        )}
      </div>
      <div className={styles.overlay}>
        <button onClick={() => setEditOn(true)} className={styles.button}>
          Edit
        </button>
      </div>
    </div>
  );
};

export default Overlay2;
