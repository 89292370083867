import React, { useEffect, useRef, useState } from "react";
import { adminAPI } from "../../api/api";
import Caption from "../caption";
import styles from "../../styles/admin/AboutusAdmin.module.css";

const AboutusAdmin = ({
  aboutUs = { description: "", companyInfo: "" },
  editOn = false,
  setEditOn = () => {},
  getInfo = () => {},
}) => {
  const [text, setText] = useState(aboutUs?.description);
  const [text2, setText2] = useState(aboutUs?.companyInfo);

  useEffect(() => {
    setText(aboutUs?.description);
    setText2(aboutUs?.companyInfo);
  }, [aboutUs]);

  const textAreaRef = useRef(null);
  const textAreaRef2 = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      const textarea = event.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      textarea.value =
        textarea.value.substring(0, start) +
        "\t" +
        textarea.value.substring(end);
      textarea.selectionEnd = end + 1;
      setText2(textarea.value);
    }
  };

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  }, [text, editOn]);

  useEffect(() => {
    if (textAreaRef2.current) {
      textAreaRef2.current.style.height = "auto";
      textAreaRef2.current.style.height =
        textAreaRef2.current.scrollHeight + "px";
    }
  }, [text2, editOn]);

  if (editOn)
    return (
      <div className={styles.container}>
        <textarea
          value={text}
          ref={textAreaRef}
          onChange={(e) => setText(e.target.value)}
          className={styles.textarea}
        />
        <textarea
          value={text2}
          ref={textAreaRef2}
          onChange={(e) => setText2(e.target.value)}
          onKeyDown={handleKeyDown}
          className={styles.textarea}
        />
        <div className={styles.buttonGroup}>
          <button
            onClick={async () => {
              await adminAPI.editInfo({
                aboutUs: {
                  description: text,
                  companyInfo: text2,
                },
              });
              getInfo();
              setEditOn(false);
            }}
            className={styles.button}
          >
            Зберегти
          </button>
          <button onClick={() => setEditOn(false)} className={styles.button}>
            Скасувати
          </button>
        </div>
      </div>
    );

  return (
    <div className={styles.container}>
      <Caption text="Про нас" margin="0px 0px 20px" />
      <div className={styles.description}>
        {aboutUs.description &&
          aboutUs.description.split("\n\n").map((block, index) => (
            <div key={index}>
              <span>{block}</span>
            </div>
          ))}
      </div>
      {aboutUs.companyInfo &&
        aboutUs.companyInfo?.split("\n\n\n").map((block) => {
          block = block?.split("\n\n");
          return (
            <div className={styles.block}>
              <h2 className={styles.title}>{block[0]}</h2>
              {block[1]?.split("\n").map((block) => {
                block = block?.split("\t");
                return (
                  <div className={styles.infoRow}>
                    <span className={styles.infoLabel}>{block[0]}</span>
                    <span>{block[1]}</span>
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
};

export default AboutusAdmin;
