import Caption from "../caption";
import s from "../../styles/general/icons.module.css";
import styles from "../../styles/admin/Contacts2Admin.module.css";
import { useEffect, useState } from "react";
import { adminAPI } from "../../api/api";

const Contacts2Admin = ({
  contacts = {
    company: "",
    contactPerson: "",
    phone: "",
    site: "",
    email: "",
    telegram: "",
    viber: "",
    address: "",
  },
  editOn = false,
  setEditOn = () => {},
  getInfo = () => {},
}) => {
  const [Contacts, setContacts] = useState(contacts);
  useEffect(() => {
    setContacts(contacts);
  }, [contacts]);

  if (editOn)
    return (
      <div className={styles.editContainer}>
        <div className={styles.column}>
          <div className={styles.row}>
            <label>Компанія:</label>
            <input
              onChange={(e) =>
                setContacts({ ...Contacts, company: e.target.value })
              }
              value={Contacts?.company}
            ></input>
          </div>
          <div className={styles.row}>
            <label>Контактна особа:</label>
            <input
              onChange={(e) =>
                setContacts({ ...Contacts, contactPerson: e.target.value })
              }
              value={Contacts?.contactPerson}
            ></input>
          </div>
          <div className={styles.row}>
            <label>Телефон:</label>
            <input
              onChange={(e) =>
                setContacts({ ...Contacts, phone: e.target.value })
              }
              value={Contacts?.phone}
            ></input>
          </div>
          <div className={styles.row}>
            <label>Сайт:</label>
            <input
              onChange={(e) =>
                setContacts({ ...Contacts, site: e.target.value })
              }
              value={Contacts?.site}
            ></input>
          </div>
          <div className={styles.row}>
            <label>Email:</label>
            <input
              onChange={(e) =>
                setContacts({ ...Contacts, email: e.target.value })
              }
              value={Contacts?.email}
            ></input>
          </div>
          <div className={styles.row}>
            <label>Telegram:</label>
            <input
              onChange={(e) =>
                setContacts({ ...Contacts, telegram: e.target.value })
              }
              value={Contacts?.telegram}
            ></input>
          </div>
          <div className={styles.row}>
            <label>Viber:</label>
            <input
              onChange={(e) =>
                setContacts({ ...Contacts, viber: e.target.value })
              }
              value={Contacts?.viber}
            ></input>
          </div>
          <div className={styles.row}>
            <label>Адреса:</label>
            <input
              onChange={(e) =>
                setContacts({ ...Contacts, address: e.target.value })
              }
              value={Contacts?.address}
            ></input>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <button
            onClick={async () => {
              await adminAPI.editInfo({
                contacts: Contacts,
              });
              getInfo();
              setEditOn(false);
            }}
            className={styles.saveButton}
          >
            Зберегти
          </button>
          <button onClick={() => setEditOn(false)} className={styles.saveButton}>
            Скасувати
          </button>
        </div>
      </div>
    );

  return (
    <div className={styles.viewContainer}>
      <Caption text={"Контакти"}></Caption>
      <div>
        <div className={styles.infoRow}>
          <span className={styles.label}>Компанія:</span>
          <span>{contacts?.company}</span>
        </div>
        <div className={`${styles.infoRow} ${styles.borderTop}`}>
          <span className={styles.label}>Контактна особа</span>
          <span>{contacts?.contactPerson}</span>
        </div>
        <div className={`${styles.infoRow} ${styles.borderTop}`}>
          <span className={styles.label}>Телефон</span>
          <span>
            {contacts?.phone?.slice(0, 4) +
              " (" +
              contacts?.phone?.slice(4, 6) +
              ") " +
              contacts?.phone?.slice(6, 9) +
              "-" +
              contacts?.phone?.slice(9, 11) +
              "-" +
              contacts?.phone?.slice(11, 13)}
          </span>
        </div>
        <div className={`${styles.infoRow} ${styles.borderTop}`}>
          <span className={styles.label}>Сайт</span>
          <span className={s.content12} style={{ color: "#f5643e" }}>
            {contacts?.site}
          </span>
        </div>
        <div className={`${styles.infoRow} ${styles.borderTop}`}>
          <span className={styles.label}>Email</span>
          <span className={s.content12} style={{ color: "#f5643e" }}>
            {contacts?.email}
          </span>
        </div>
        <div className={`${styles.infoRow} ${styles.borderTop}`}>
          <span className={styles.label}>Telegram</span>
          <span>{contacts?.telegram}</span>
        </div>
        <div className={`${styles.infoRow} ${styles.borderTop}`}>
          <span className={styles.label}>Viber</span>
          <span>{contacts?.viber}</span>
        </div>
        <div className={`${styles.infoRow} ${styles.borderTop}`}>
          <span className={styles.label}>Адреса</span>
          <span style={{ fontStyle: "italic" }}>{contacts?.address}</span>
        </div>
      </div>
    </div>
  );
};

export default Contacts2Admin;
