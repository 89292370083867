import styles from "../../styles/admin/SearchOptionsAdmin.module.css";

const SearchOptionsAdmin = ({
  setSearchOption = () => {},
  setCurrentPage = () => {},
  selected = "",
  options = [
    { text: "Продукти", selected: "" },
    { text: "Категорії", selected: "category" },
  ],
}) => {
  return (
    <div>
      <span className={styles.searchLabel}>Пошук: </span>
      <select
        onChange={(e) => {
          setSearchOption(e.target.value);
          setCurrentPage(1);
        }}
        className={styles.select}
      >
        {options.map((option) => (
          <option
            selected={selected === option.selected}
            value={option.selected}
            className={styles.option}
          >
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SearchOptionsAdmin;
