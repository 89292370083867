import React from "react";
import s from "../../styles/general/overlay.module.css";
import styles from "../../styles/admin/OrderInfoAdmin.module.css";

const OrderInfoAdmin = ({ orderInfo = "", setEditOn = () => {} }) => {
  if (orderInfo === "") {
    return (
      <div className={`${s.container} ${styles.container}`}>
        <div className={`${s.child} ${styles.child}`}></div>
        <div className={styles.overlay}>
          <button onClick={() => setEditOn(true)} className={styles.button}>
            Add
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className={`${s.container} ${styles.container}`}>
      <div className={`${s.child} ${styles.child}`}>
        {orderInfo.split("\n\n").map((block, index) => (
          <div className={styles.infoBlock} key={`block-${index}`}>
            {block.split("\n").map((substring, subIndex) => {
              const info = substring.split("\t");
              return (
                <div className={styles.infoRow} key={`row-${subIndex}`}>
                  <span className={styles.infoLabel}>{info[0] || ""}</span>
                  {info[1] ? ` ${info[1]}` : " "}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <div className={styles.overlay}>
        <button onClick={() => setEditOn(true)} className={styles.button}>
          Edit
        </button>
      </div>
    </div>
  );
};

export default OrderInfoAdmin;
