import React from "react";
import s from "../../styles/general/overlay.module.css";
import styles from "../../styles/admin/SpecsAdmin.module.css";

const SpecsAdmin = ({ data = "", setEditOn = () => {} }) => {
  if (data === "") {
    return (
      <div className={`${s.container} ${styles.addContainer}`}>
        <div className={s.child}></div>
        <div className={styles.overlay}>
          <button onClick={() => setEditOn(true)} className={styles.addButton}>
            Add
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={`${s.container} ${styles.tableContainer}`}>
      <table className={`${s.child} ${styles.table}`}>
        <tbody>
          {data.split("\n\n").map((block, blockIndex) => (
            <React.Fragment key={blockIndex}>
              {block.split("\n").map((text, index) => {
                if (index === 0) {
                  return (
                    <tr key={`header-${blockIndex}`}>
                      <th className={styles.tableHeader} colSpan="2">
                        {text}
                      </th>
                    </tr>
                  );
                } else {
                  return (
                    <tr
                      key={`row-${blockIndex}-${index}`}
                      className={styles.tableRow}
                    >
                      {text.split("\t").map((column, columnIndex) => (
                        <td
                          key={`cell-${blockIndex}-${index}-${columnIndex}`}
                          className={styles.tableCell}
                        >
                          {column}
                        </td>
                      ))}
                    </tr>
                  );
                }
              })}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <div className={styles.overlay}>
        <button onClick={() => setEditOn(true)} className={styles.editButton}>
          Edit
        </button>
      </div>
    </div>
  );
};

export default SpecsAdmin;
