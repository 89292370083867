import React, { useEffect, useState } from "react";
import styles from "../../styles/admin/InvoicePDF.module.css";
import { publicAPI } from "../../api/api";
import HOST from "../../env";

const InvoicePDF = React.forwardRef(({ order }, ref) => {
  const [info, setInfo] = useState({});

  const isPeriodValid = (discountPeriod) => {
    const startNumber = new Date(discountPeriod?.start).getTime();
    const endNumber = new Date(discountPeriod?.end).getTime();
    const nowNumber = new Date().getTime();
    return nowNumber < endNumber && endNumber - startNumber > 0;
  };

  useEffect(() => {
    const getInfo = async () => {
      try {
        const res = await publicAPI.getInfo();
        setInfo(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getInfo();
  }, []);

  return (
    <div ref={ref} className={styles.container}>
      <div className={styles.header}>
        <span className={styles.headerText}>Накладна №{order.orderNumber}</span>
        <span>{new Date().toLocaleDateString()}</span>
      </div>
      <div>
        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>Продавець:</h3>
          <p>{info?.contacts?.site}</p>
          <p>Адреса: {info?.contacts?.address}</p>
          <p>Телефон: {info?.contacts?.phone}</p>
          <p>Email: {info?.contacts?.email}</p>
        </div>
        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>Інформація про замовника:</h3>
          <p>Ім'я: {order?.details?.name}</p>
          <p>Телефон: {order?.details?.phone}</p>
        </div>
        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>Інформація про доставку:</h3>
          <p>Адреса: {order?.details?.settlement}</p>
          <p>Спосіб доставки: {order?.details?.postalService}</p>
          <p>Спосіб оплати: {order?.paymentType}</p>
          <p>Коментар: {order?.details?.comment || "Відсутній"}</p>
        </div>
      </div>
      <div className={styles.productList}>
        {order?.details?.cart.map((obj, i) => {
          const { imageURL, title, code } = obj.product;
          let price;
          let entities = obj.product.wholesalePrices.filter(
            (entity) => entity.count <= obj.count
          );
          if (entities.length > 0) price = entities[entities.length - 1].price;
          else
            price =
              obj.product.discountPrice &&
              isPeriodValid(obj.product.discountPeriod)
                ? obj.product.discountPrice
                : obj.product.price;
          return (
            <div key={i} className={styles.productItem}>
              <div className={styles.productInfo}>
                <span className={styles.productIndex}>{i + 1}.</span>
                <img
                  className={styles.productImage}
                  src={`${HOST}/${imageURL}`}
                  alt={title}
                />
                <div className={styles.productDetails}>
                  <span className={styles.productTitle}>{title}</span>
                  <p>Код товару: {code}</p>
                </div>
              </div>
              <div className={styles.productPrice}>
                <span>Кількість: {obj.count}</span>
                <p>{price} ₴</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.total}>
        <span>Всього:</span>
        <span className={styles.totalValue}>
          {order?.details?.sum} ₴
        </span>
      </div>
    </div>
  );
});

export default InvoicePDF;
