import { deleteCookie } from "../../App";
import s2 from "../../styles/general/animations.module.css";
import styles from "../../styles/admin/MenuAdmin.module.css";
import { useNavigate } from "react-router-dom";
import useConditionalNavigate from "../custom_hooks/use_conditional_navigate";
import { adminAPI } from "../../api/api";
import { useState } from "react";

const MenuAdmin = ({ setIsLogged = () => {} }) => {
  const navigate = useConditionalNavigate();
  const [isHidden, setIsHidden] = useState(true);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className={`${styles.container} ${isOpen ? styles.open : ""}`}>
      <div className={styles.menuIcon} onClick={toggleMenu}>
        &#9776;
      </div>
      <div className={`${s2.parent} ${styles.menuList}`}>
        {[
          { path: "/administrator/categories", label: "Категорії" },
          { path: "/administrator/products", label: "Товари" },
          { path: "/administrator/feedback", label: "Відгуки" },
          { path: "/administrator/orders", label: "Замовлення" },
          { path: "/administrator/info", label: "Інфо" },
        ].map(({ path, label }) => (
          <span className={styles.menuItem} key={path}>
            <a
              className={`${s2.menu} ${styles.menuLink}`}
              onClick={(e) => {
                navigate(path, e);
                setIsOpen(false);
              }}
            >
              {label}
            </a>
          </span>
        ))}
        <span
          className={styles.subMenuContainer}
          // onMouseEnter={() => !isOpen && setIsHidden(false)}
          // onMouseLeave={() => !isOpen && setIsHidden(true)}
        >
          <a
            className={`${s2.menu} ${styles.menuLink}`}
            onClick={() => setIsHidden(!isHidden)}
          >
            Дамп
          </a>
          <div className={isHidden ? styles.subMenuHidden : styles.subMenu}>
            {[
              {
                label: "Excel",
                action: async () => {
                  if (isBlocked) return;
                  setIsBlocked(true);
                  try {
                    const response = await adminAPI.exportExcel();
                    const url = window.URL.createObjectURL(
                      new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "exported_data.xlsx");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  } catch (error) {
                    console.error("Помилка під час експорту:", error);
                  } finally {
                    setIsBlocked(false);
                  }
                },
              },
              {
                label: "Mongo",
                action: async () => {
                  if (isBlocked) return;
                  setIsBlocked(true);
                  try {
                    const response = await adminAPI.backup();
                    const contentDisposition =
                      response.headers["content-disposition"];
                    const matches =
                      contentDisposition.match(/filename="?([^"]+)"?/);
                    const filename = matches ? matches[1] : "dump.zip";
                    const url = window.URL.createObjectURL(
                      new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  } catch (error) {
                    console.error("Помилка під час експорту:", error);
                  } finally {
                    setIsBlocked(false);
                  }
                },
              },
            ].map(({ label, action }) => (
              <span className={styles.subMenuItem} key={label}>
                <a
                  className={`${s2.menu}`}
                  style={{ color: "#fff", padding: "0", cursor: "pointer" }}
                  onClick={action}
                >
                  {label}
                </a>
              </span>
            ))}
          </div>
        </span>
      </div>
      <div className={styles.logout}>
        <span
          onClick={() => {
            deleteCookie("token");
            setIsLogged(false);
          }}
          className={s2.menu}
        >
          Logout
        </span>
      </div>
    </div>
  );
};

export default MenuAdmin;
