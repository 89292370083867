import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import s from "../../styles/general/overlay.module.css";
import DescriptionAdmin from "./description_admin";
import { adminAPI, publicAPI } from "../../api/api";
import HOST from "../../env";
import ConfirmDeleteModal from "./confirm_delete_modal";
import useConditionalNavigate from "../custom_hooks/use_conditional_navigate";
import styles from "../../styles/admin/CategoriesAdmin.module.css";

const CategoriesAdmin = () => {
  const [category, setCategory] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [onConfirm, setOnConfirm] = useState(() => {});
  const location = useLocation().pathname.split("/");
  let _id = location[location.length - 1];
  if (_id === "categories") _id = "";

  const getCategory = async () => {
    try {
      const res = await publicAPI.getCategory(_id);
      setCategory(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCategory();
  }, [_id]);

  const [editOn, setEditOn] = useState(false);
  const [text, setText] = useState(category?.title || "");
  const textAreaRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      const textarea = event.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      textarea.value =
        textarea.value.substring(0, start) +
        "\t" +
        textarea.value.substring(end);
      textarea.selectionEnd = end + 1;
      setText(textarea.value);
    }
  };

  useEffect(() => {
    if (textAreaRef.current) {
      const savedScrollPosition = document.documentElement.scrollTop;
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
      window.scrollTo(0, savedScrollPosition);
    }
  }, [text, editOn]);

  useEffect(() => {
    setText(category?.description || "");
    setEditOn(false);
  }, [category]);

  return (
    <div className={styles.container}>
      {category?.title !== "Група товарів" ? (
       !editOn ? (
        <DescriptionAdmin
          data={category?.description}
          captionText={category?.title}
          setEditOn={setEditOn}
          deleteOn={true}
          deleteDescription={() =>
            adminAPI.editCategory(_id, { description: "" })
          }
          getCategory={getCategory}
        ></DescriptionAdmin>
      ) : (
          <div className={styles.editWrapper}>
            <textarea
              value={text}
              ref={textAreaRef}
              onChange={(e) => setText(e.target.value)}
              onKeyDown={handleKeyDown}
              className={styles.editTextarea}
            ></textarea>
            <div className={styles.buttonGroup}>
              <button
                onClick={async () => {
                  try {
                    await adminAPI.editCategory(_id, { description: text });
                    getCategory();
                    setEditOn(false);
                  } catch (err) {
                    console.log(err);
                  }
                }}
                className={styles.button}
              >
                Зберегти
              </button>
              <button
                onClick={() => {
                  setText(category?.description || "");
                  setEditOn(false);
                }}
                className={styles.button}
              >
                Скасувати
              </button>
            </div>
          </div>
        )
      ) : null}

      <div className={styles.categoryWrapper}>
        <ConfirmDeleteModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onConfirm={onConfirm}
        />
        {category?.categories?.map((category) => (
          <Category
            key={category._id}
            {...category}
            getCategory={getCategory}
            setIsOpen={setIsOpen}
            setOnConfirm={setOnConfirm}
          />
        ))}
        <div className={styles.addCategoryButton}>
          <svg
            onClick={async () => {
              try {
                await adminAPI.addCategory(_id, {
                  title: "Нова категорія",
                  imageURL: "",
                  itemsIn: 0,
                  description: "",
                  categories: [],
                });
                getCategory();
              } catch (err) {
                console.log(err);
              }
            }}
            className={styles.addCategoryIcon}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="40"
            height="40"
          >
            <path d="M12 2c.6 0 1 .4 1 1v8h8c.6 0 1 .4 1 1s-.4 1-1 1h-8v8c0 .6-.4 1-1 1s-1-.4-1-1v-8H3c-.6 0-1-.4-1-1s.4-1 1-1h8V3c0-.6.4-1 1-1z" />
          </svg>
        </div>
      </div>
    </div>
  );
};

const Category = ({
  title = "",
  imageURL = "",
  _id = "",
  getCategory,
  setIsOpen,
  setOnConfirm,
}) => {
  const ref = useRef(null);
  const navigate = useConditionalNavigate();
  const [editOn, setEditOn] = useState(false);
  const [Category, setCategory] = useState({ title, imageURL, file: null });

  useEffect(() => {
    setCategory({ title, imageURL });
  }, [title, imageURL]);

  return (
    <div className={`${!editOn && s.container} ${styles.categoryGrid}`}>
      <div
        className={`${s.child} ${styles.categoryCard}`}
        onClick={(e) =>
          !editOn && navigate("/administrator/subcategories/" + _id, e)
        }
      >
        <div className={styles.imageAndDragDrop}>
          <div
            className={styles.imageWrapper}
            style={{ filter: editOn ? "brightness(70%)" : "" }}
          >
            <img
              className={styles.image}
              src={`${HOST}/${Category.imageURL}`}
              ref={ref}
            />
          </div>
          {editOn && (
            <div
              className={styles.dragDropOverlay}
              onDrop={async (e) => {
                e.preventDefault();
                const files = e.dataTransfer.files;
                if (
                  /^image\/(jpeg|png|gif|webp|bmp|svg\+xml)$/.test(
                    files[0]?.type
                  )
                ) {
                  const reader = new FileReader();
                  reader.onload = (event) => {
                    const url = event.target.result;
                    ref.current.src = url;
                  };
                  reader.readAsDataURL(files[0]);
                  const newFileName = `${Number(new Date())}_${files[0].name}`;
                  const updatedFile = new File([files[0]], newFileName, {
                    type: files[0].type,
                  });
                  setCategory({ ...Category, file: updatedFile });
                }
              }}
              onDragOver={(e) => e.preventDefault()}
            >
              <span className={styles.dragDropText}>Drag & Drop</span>
            </div>
          )}
        </div>
        {editOn ? (
          <div className={styles.inputWrapper}>
            <input
              className={styles.input}
              value={Category.title}
              onChange={(e) =>
                setCategory({ ...Category, title: e.target.value })
              }
            />
          </div>
        ) : (
          <div className={styles.inputWrapper}>
            <span className={styles.textHover}>{Category.title}</span>
          </div>
        )}
      </div>

      {!editOn ? (
        <div className={styles.hiddenButtons}>
          <button
            onClick={() => setEditOn(true)}
            className={styles.hiddenButton}
          >
            Edit
          </button>
          <button
            onClick={() => {
              setIsOpen(true);
              setOnConfirm(() => async () => {
                try {
                  await adminAPI.deleteCategory(_id);
                  getCategory();
                } catch (err) {
                  console.log(err);
                }
              });
            }}
            className={styles.hiddenButton}
          >
            Del
          </button>
        </div>
      ) : (
        <div className={styles.buttonGroup}>
          <button
            onClick={async () => {
              const formData = new FormData();
              formData.append("title", Category.title);
              formData.append("file", Category.file);
              try {
                await adminAPI.editCategory(_id, formData);
                getCategory();
                setEditOn(false);
              } catch (err) {
                console.log(err);
              }
            }}
            className={styles.button}
          >
            Зберегти
          </button>
          <button
            onClick={() => {
              ref.current.src = `${HOST}/${imageURL}`;
              setCategory({ title, imageURL });
              setEditOn(false);
            }}
            className={styles.button}
          >
            Скасувати
          </button>
        </div>
      )}
    </div>
  );
};

export const recursiveSearch = (categories = [], id) => {
  if (categories.length === 0) return undefined;

  let result = categories.find((category) => category._id === id);

  if (result) {
    return result;
  } else {
    for (const category of categories) {
      const found = recursiveSearch(category.categories, id);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
};

export default CategoriesAdmin;
