import { useEffect, useState } from "react";
import HOST from "../../env";
import { SearchResultsAdmin } from "./search_products_admin";
import { publicAPI } from "../../api/api";
import styles from "../../styles/admin/CartAdmin.module.css";

const CartAdmin = ({ cart = [], setCart = () => {}, setIsChanges = () => {}, cartOpen = false, setCartOpen = () => {} }) => {
  const [addProdOn, setAddProdOn] = useState(false);
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [filter, setFilter] = useState([]);
  const getProducts = async (query = "") => {
    try {
      const res = await publicAPI.getProducts("", `${query}`);
      setFilter(res.data.products);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <div className={styles.container}>
        <button className={styles.button} onClick={() => setCartOpen(!cartOpen)}>Кошик: &#x25BE;</button>
        <div>
          {addProdOn && (
            <div className={styles.inputContainer}>
              <input className={styles.input} onBlur={() => {
                setTimeout(() => {
                  setAddProdOn(false);
                  setFilter([]);
                }, 200);
              }} onChange={(e) => {
                clearTimeout(debounceTimer);
                if (e.target.value === "") return setFilter([]);
                setDebounceTimer(setTimeout(() => {
                  getProducts(`title=${e.target.value}`);
                }, 1000));
              }} />
              <SearchResultsAdmin filter={filter.map((product) => product?.title)} gap="25px" setValue={(title) => {
                if (filter.length > 0) {
                  const newProd = filter.find((product) => product.title === title);
                  if (newProd) {
                    setCart([...cart, { product: newProd, count: 1 }]);
                    setIsChanges(true);
                  }
                }
              }} />
            </div>
          )}
          {!addProdOn && (
            <div className={styles.svgContainer}>
              <svg onClick={() => setAddProdOn(true)} className={styles.svgIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                <path d="M12 2c.6 0 1 .4 1 1v8h8c.6 0 1 .4 1 1s-.4 1-1 1h-8v8c0 .6-.4 1-1 1s-1-.4-1-1v-8H3c-.6 0-1-.4-1-1s.4-1 1-1h8V3c0-.6.4-1 1-1z" />
              </svg>
            </div>
          )}
        </div>
      </div>
      {cart.length > 0 && cartOpen && cart.map((entity) => (
        <CartItem cart={cart} setCart={setCart} setIsChanges={setIsChanges} {...entity?.product} count={entity?.count} />
      ))}
    </div>
  );
};

const CartItem = ({ title = "", imageURL = "", price = "", discountPrice = "", discountPeriod = { start: null, end: null }, wholesalePrices = [], isAvailable = "", toOrder = {}, count = 1, cart = [], setCart = () => {}, setIsChanges = () => {} }) => {
  const isPeriodValid = () => {
    const startNumber = new Date(discountPeriod?.start).getTime();
    const endNumber = new Date(discountPeriod?.end).getTime();
    const nowNumber = new Date().getTime();
    return nowNumber < endNumber && endNumber - startNumber > 0;
  };
  const changeCount = (count) => {
    if (count < 1) return;
    let index;
    cart.find((entity, i) => {
      if (entity.product.title === title) index = i;
    });
    const newCart = [...cart];
    if (index !== undefined) {
      newCart[index].count = count;
      setCart(newCart);
      setIsChanges(true);
    }
  };
  const deleteItemFromCart = () => {
    setCart(cart.filter((entity) => entity.product.title !== title));
    setIsChanges(true);
  };
  const [Count, setCount] = useState(count);
  useEffect(() => {
    setCount(count);
  }, [count]);
  return (
    <div className={styles.item}>
      <div>
        <img className={styles.image} src={`${HOST}/${imageURL}`} />
      </div>
      <div className={styles.details}>
        <div className={styles.titleContainer}>
          <span>{title}</span>
          <div onClick={() => deleteItemFromCart()}>
            <svg className={styles.deleteIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="currentColor" d="M12 3c.6 0 1 .4 1 1v1h6v3h1c.6 0 1 .4 1 1s-.4 1-1 1h-1v8c0 1.7-1.3 3-3 3H8c-1.7 0-3-1.3-3-3v-8H4c-.6 0-1-.4-1-1s.4-1 1-1h1V5h6V4c0-.5.4-.9.9-1h.1Zm5 7H7v8c0 .6.4 1 1 1h8c.6 0 1-.4 1-1v-8Zm-3 2c.6 0 1 .4 1 1v3c0 .6-.4 1-1 1s-1-.4-1-1v-3c0-.6.4-1 1-1Zm-4 0c.6 0 1 .4 1 1v3c0 .6-.4 1-1 1s-1-.4-1-1v-3c0-.6.4-1 1-1Zm7-5H7v1h10V7Z" />
            </svg>
          </div>
        </div>
        <span className={styles.stockStatus}>{isAvailable ? "В наявності" : toOrder?.on ? "Під замовлення" : "Немає в наявності"}</span>
        <span className={styles.price}>{discountPrice && isPeriodValid() ? discountPrice : price}</span>
        <div className={styles.quantityControls}>
          <button onClick={() => changeCount(Count - 1)} className={styles.quantityButton}>-</button>
          <input className={styles.quantityInput} value={Count} onChange={(e) => setCount(e.target.value)} onBlur={() => changeCount(Count)} />
          <button onClick={() => changeCount(Count + 1)} className={styles.quantityButton}>+</button>
        </div>
        <div className={styles.totalPrice}>
          {discountPrice && isPeriodValid() ? +discountPrice * count + " ₴" : wholesalePrices.some((entity) => entity.count <= count) ? "опт" : +price * count + " ₴"}
        </div>
      </div>
    </div>
  );
};

export default CartAdmin;
