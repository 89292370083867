import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Flag from "../flag";
import s from "../../styles/general/overlay.module.css";
import styles from "../../styles/admin/ProductsAdmin.module.css";
import Pagination from "../pagination";
import { adminAPI, publicAPI } from "../../api/api";
import HOST from "../../env";
import FilterAdmin from "./filter_admin";
import ConfirmDeleteModal from "./confirm_delete_modal";
import useConditionalNavigate from "../custom_hooks/use_conditional_navigate";
import SearchOptionsAdmin from "./search_options_admin";
import SearchInputAdmin from "./search_products_admin";

const ProductsAdmin = ({
  filter = "",
  filterValue = "",
  inputValue = "",
  currentPage = "",
  searchOption = "",
  setFilter = () => {},
  setFilterValue = () => {},
  setInputValue = () => {},
  setCurrentPage = () => {},
  setSearchOption = () => {},
}) => {
  const navigate = useConditionalNavigate();
  const [pageSize] = useState(16);
  const [products, setProducts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [onConfirm, setOnConfirm] = useState(() => {});
  const [debounceTimer, setDebounceTimer] = useState(null);

  const getProducts = async (query = "") => {
    try {
      const res = await publicAPI.getProducts(
        "",
        `currentPage=${currentPage}&filter=${filter}&filterValue=${filterValue}&pageSize=${pageSize}&${query}`
      );
      setProducts(res.data.products);
      setTotalCount(res.data.totalCount);
    } catch (err) {
      console.log(err);
    }
  };

  const asyncSearch = async () => {
    clearTimeout(debounceTimer);
    setDebounceTimer(
      setTimeout(async () => {
        let query = `title=${inputValue}`;
        if (searchOption === "category") {
          const category = await publicAPI.getCategory(
            "",
            `title=${inputValue}`
          );
          if (category.data.categories.length > 0)
            query = `categoryId=${category.data.categories[0]._id}`;
        }
        getProducts(query);
      }, 500)
    );
  };

  useEffect(() => {
    asyncSearch();
  }, [currentPage, filter, filterValue, searchOption, inputValue]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <SearchInputAdmin
          inputValue={inputValue}
          setInputValue={setInputValue}
          setCurrentPage={setCurrentPage}
        />
        <div className={styles.controls}>
          <div className={styles.filters}>
            <SearchOptionsAdmin
              setSearchOption={setSearchOption}
              setCurrentPage={setCurrentPage}
              selected={searchOption}
            />
            <FilterAdmin
              setFilter={setFilter}
              setFilterValue={setFilterValue}
              selected={`${filter}_${filterValue}`}
              setCurrentPage={setCurrentPage}
            />
          </div>
          <Pagination
            totalCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
      <div className={styles.productsWrapper}>
        <ConfirmDeleteModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onConfirm={onConfirm}
        />
        {products.map((product) => (
          <div
            className={`${s.container} ${styles.relativeGrid}`}
            key={product._id}
          >
            <ProductPreviewAdmin {...product} />
            <div className={styles.buttonsWrapper}>
              <button
                onClick={(e) =>
                  navigate(
                    product?._id
                      ? `/administrator/products/edit/${product._id}`
                      : "/administrator/products/edit/",
                    e
                  )
                }
                className={styles.button}
              >
                Edit
              </button>
              <button
                onClick={() => {
                  setIsOpen(true);
                  setOnConfirm(() => async () => {
                    try {
                      await adminAPI.deleteProduct(product._id);
                      asyncSearch();
                      setIsOpen(false);
                    } catch (err) {
                      console.log(err);
                    }
                  });
                }}
                className={styles.button}
              >
                Del
              </button>
            </div>
          </div>
        ))}
        <div className={styles.addButton}>
          <svg
            className={styles.svg}
            onClick={async () => {
              try {
                await adminAPI.addProduct({
                  imageURL: "",
                  pictures: ["", "", ""],
                  title: "Новий продукт",
                  price: 0,
                  isAvailable: false,
                  code: null,
                  flag: "",
                  description: "",
                  specs: "",
                  orderInfo: "",
                  category: null,
                });
                if (inputValue === "Новий продукт") asyncSearch();
                else setInputValue("Новий продукт");
              } catch (err) {
                console.log(err);
              }
            }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="40"
            height="40"
          >
            <path d="M12 2c.6 0 1 .4 1 1v8h8c.6 0 1 .4 1 1s-.4 1-1 1h-8v8c0 .6-.4 1-1 1s-1-.4-1-1v-8H3c-.6 0-1-.4-1-1s.4-1 1-1h8V3c0-.6.4-1 1-1z" />
          </svg>
        </div>
      </div>
    </div>
  );
};

const ProductPreviewAdmin = ({
  title = "",
  imageURL = "",
  price = "",
  discountPrice = "",
  discountPeriod = { start: null, end: null },
  isAvailable = "",
  toOrder = { on: false, dayCount: null },
  code = "",
  _id = "",
  flag = "",
}) => {
  const isPeriodValid = () => {
    const startNumber = new Date(discountPeriod.start).getTime();
    const endNumber = new Date(discountPeriod.end).getTime();
    const nowNumber = new Date().getTime();
    return nowNumber < endNumber && endNumber - startNumber > 0;
  };
  const navigate = useConditionalNavigate();
  return (
    <div
      onClick={(e) => navigate("/administrator/product/" + _id, e)}
      className={`${styles.productPreview} ${s.child}`}
    >
      {flag && <Flag text={flag} />}
      <div className={styles.imageContainer}>
        <img src={`${HOST}/${imageURL}`} className={styles.productImage} />
      </div>
      <div className={styles.productDetails}>
        {code && <span className={styles.code}>Код: {code}</span>}
        <span>{title}</span>
        {isAvailable ? (
          <span className={styles.availabilityInStock}>В наявності</span>
        ) : toOrder.on && toOrder.dayCount ? (
          <span className={styles.availabilityOnOrder}>
            Під замовлення ({toOrder.dayCount} днів)
          </span>
        ) : (
          <span className={styles.availabilityOutOfStock}>
            Немає в наявності
          </span>
        )}
        {(!discountPrice || !isPeriodValid()) && (
          <span className={styles.priceBox}>{price + " ₴"}</span>
        )}
        {discountPrice && isPeriodValid() && (
          <div className={styles.discountBox}>
            <span className={styles.discountOldPrice}>{price}</span>
            <span className={styles.discountNewPrice}>
              {discountPrice + " ₴"}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductsAdmin;
