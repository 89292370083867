import React, { useRef, useState } from "react";
import styles from "../styles/Footer.module.css";

const Footer = () => {
  const nativeWidth = useRef(window.innerWidth);
  const [iFullVersion, setIsFullVersion] = useState(nativeWidth.current >= 1024);
  return (
    <footer className={styles.footer}>
      {navigator.userAgent.includes("Mobile") && (
        <div className={styles.buttonWrapper}>
          <button
            className={styles.fullVButton}
            onClick={() => {
              if (!iFullVersion) {
                document
                  .querySelector("meta[name='viewport']")
                  .setAttribute("content", "width=1024");
                setIsFullVersion(!iFullVersion);
              } else {
                document
                  .querySelector("meta[name='viewport']")
                  .setAttribute("content", `width=${nativeWidth.current}`);
                setIsFullVersion(!iFullVersion);
              }
            }}
          >
            {iFullVersion ? "Мобільна версія сайту" : "Повна версія сайту"}
          </button>
        </div>
      )}
    </footer>
  );
};

export default Footer;
