import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { SearchResultsAdmin } from "./search_products_admin";
import { MyContext } from "../..";
import { adminAPI, publicAPI } from "../../api/api";
import HOST from "../../env";
import SearchResults from "../search_results";
import styles from "../../styles/admin/EditProductPreview.module.css";

const EditProductPreview = () => {
  const [debounceTimer, setDebounceTimer] = useState(null);
  const ref = useRef(null);
  const location = useLocation().pathname.split("/");
  const _id = location[location.length - 1];
  const [product, setProduct] = useState({});
  const [categories, setCategories] = useState({});
  const [filter, setFilter] = useState([]);
  const [filter2, setFilter2] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [file, setFile] = useState();
  const [isDiscount, setIsDiscount] = useState(false);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [wholesalePrices, setWholesalePrices] = useState("");
  const [isWholeSale, setIsWholesale] = useState(false);
  const [error, setError] = useState("");

  const isPeriodValid = () => {
    const startNumber = new Date(product?.discountPeriod?.start).getTime();
    const endNumber = new Date(product?.discountPeriod?.end).getTime();
    const nowNumber = new Date().getTime();
    return nowNumber < endNumber && endNumber - startNumber > 0;
  };

  const getCategory = async (query = "") => {
    try {
      const res = await publicAPI.getCategory("", query);
      setFilter(res.data.categories.map((category) => category.title));
    } catch (err) {
      console.log(err);
    }
  };

  const getProduct = async () => {
    try {
      const res = await publicAPI.getProducts(_id);
      const product = res.data.products[0];
      setProduct(product);
      setIsDiscount(product?.discountPrice ? true : false);
      setRelatedProducts(product.relatedProducts);
      if (product.wholesalePrices.length > 0) {
        setIsWholesale(true);
        setWholesalePrices(
          product.wholesalePrices
            .map((entity) => entity.count + " - " + entity.price)
            .join("\n")
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getProducts = async (query = "") => {
    try {
      const res = await publicAPI.getProducts("", `${query}`);
      setFilter2(res.data.products);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProduct();
  }, []);

  if (product._id === undefined) return;
  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <div className={styles.imageWrapper}>
          <div
            className={styles.imageBackground}
            style={{
              backgroundImage: `url('${HOST}/${product.imageURL}')`,
            }}
            ref={ref}
          ></div>
          <div
            className={styles.dragDrop}
            onDrop={(e) => {
              e.preventDefault();
              const files = e.dataTransfer.files;
              if (
                /^image\/(jpeg|png|gif|webp|bmp|svg\+xml)$/.test(files[0]?.type)
              ) {
                function handleFile(file) {
                  let reader = new FileReader();
                  reader.onload = function (event) {
                    let url = event.target.result;
                    ref.current.style.backgroundImage = `url('${url}')`;
                  };
                  reader.readAsDataURL(file);
                }
                handleFile(files[0]);
                const newFileName = `${Number(new Date())}_${files[0].name}`;
                const updatedFile = new File([files[0]], newFileName, {
                  type: files[0].type,
                });
                setFile(updatedFile);
              }
            }}
            onDragOver={(e) => e.preventDefault()}
          >
            <span className={styles.dragDropText}>Drag & Drop</span>
          </div>
        </div>
        <div className={styles.inputFields}>
          <label className={styles.label}>Назва:</label>
          <textarea
            onChange={(e) => setProduct({ ...product, title: e.target.value })}
            value={product.title}
          ></textarea>
          <div className={styles.relativeWrapper}>
            <label className={styles.label}>Категорія:</label>
            <input
              onBlur={() => {
                setTimeout(() => {
                  setFilter([]);
                }, 200);
              }}
              onChange={(e) => {
                clearTimeout(debounceTimer);
                setProduct({ ...product, category: { title: e.target.value } });
                if (e.target.value === "") return setFilter([]);
                setDebounceTimer(
                  setTimeout(() => {
                    getCategory(`title=${e.target.value}`);
                  }, 1000)
                );
              }}
              value={product?.category?.title || ""}
            ></input>
            <SearchResultsAdmin
              filter={filter}
              setValue={(text) => {
                setProduct({ ...product, category: { title: text } });
                setFilter([]);
              }}
            ></SearchResultsAdmin>
          </div>
          <div className={styles.relativeWrapper}>
            <label style={{ fontWeight: "600" }}>Пов'язані товари:</label>
            <input
              onBlur={() => {
                setTimeout(() => {
                  setFilter2([]);
                }, 200);
              }}
              onInput={(e) => {
                clearTimeout(debounceTimer);
                setInputValue(e.target.value);
                if (e.target.value === "") return setFilter2([]);
                setDebounceTimer(
                  setTimeout(() => {
                    getProducts(`title=${e.target.value}`);
                  }, 1000)
                );
              }}
              value={inputValue}
              onFocus={(e) => {
                e.preventDefault();
                e.target.style.outline = "none";
              }}
            ></input>
            <SearchResultsAdmin
              filter={filter2
                .filter(
                  (prod) =>
                    !relatedProducts.find(
                      (relatedProduct) => relatedProduct._id === prod._id
                    )
                )
                .map((product) => product.title)}
              setValue={(text) => {
                const relatedProduct = filter2.find(
                  (product) => product.title === text
                );
                if (relatedProduct._id) {
                  setRelatedProducts([...relatedProducts, relatedProduct]);
                  setInputValue("");
                  setFilter2([]);
                }
              }}
              gap={"49px"}
            ></SearchResultsAdmin>
            {relatedProducts.map((relatedProduct) => (
              <div className={styles.relatedProduct} key={relatedProduct._id}>
                <span className={styles.relatedProductTitle}>
                  {relatedProduct.title}
                </span>
                <svg
                  onClick={() =>
                    setRelatedProducts(
                      relatedProducts.filter(
                        (relatedProd) => relatedProd._id !== relatedProduct._id
                      )
                    )
                  }
                  className={styles.removeIcon}
                  width="20px"
                  height="20px"
                  fill="none"
                  viewBox="0 0 24 24"
                  data-testid="SvgClose"
                  data-qaid="SvgClose"
                  focusable="false"
                  aria-hidden="true"
                  tabIndex="-1"
                >
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M4.414 3A1 1 0 0 0 3 4.414L10.586 12 3 19.586A1 1 0 1 0 4.414 21L12 13.414 19.586 21A1 1 0 0 0 21 19.586L13.414 12 21 4.414A1 1 0 0 0 19.586 3L12 10.586 4.414 3Z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
            ))}
          </div>

          <label className={styles.label}>Код:</label>
          <input
            onChange={(e) => setProduct({ ...product, code: e.target.value })}
            value={product.code}
          ></input>

          <label className={styles.label}>Прапорець:</label>
          <input
            onChange={(e) => setProduct({ ...product, flag: e.target.value })}
            value={product.flag}
          ></input>

          <div className={styles.checkboxGroup}>
            <input
              type="checkbox"
              checked={product.isAvailable}
              onChange={() =>
                setProduct({
                  ...product,
                  isAvailable: !product.isAvailable,
                  toOrder: !product.isAvailable
                    ? { on: false, dayCount: null }
                    : product.toOrder,
                })
              }
            />
            <span>В наявності</span>
          </div>

          <div className={styles.checkboxGroup}>
            <input
              type="checkbox"
              checked={product?.toOrder?.on}
              onChange={() =>
                setProduct({
                  ...product,
                  isAvailable: false,
                  toOrder: { on: !product?.toOrder?.on, dayCount: null },
                })
              }
            />
            <span>Під замовлення</span>
          </div>

          {product?.toOrder?.on && (
            <>
              <label className={styles.label}>Днів:</label>
              <input
                value={product?.toOrder?.dayCount}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    toOrder: {
                      ...product.toOrder,
                      dayCount: e.target.value,
                    },
                  })
                }
              />
            </>
          )}

          <div className={styles.checkboxGroup}>
            <input
              type="checkbox"
              checked={product.recommended}
              onChange={() =>
                setProduct({ ...product, recommended: !product.recommended })
              }
            />
            <span>Рекомендовано</span>
          </div>

          <div className={styles.checkboxGroup}>
            <input
              type="checkbox"
              checked={isDiscount}
              onChange={() => {
                setIsWholesale(false);
                setWholesalePrices("");
                setIsDiscount(!isDiscount);
                if (isDiscount)
                  setProduct({
                    ...product,
                    discountPrice: null,
                    discountPeriod: { start: null, end: null },
                  });
              }}
            />
            <span>Знижка</span>
          </div>

          {isDiscount && (
            <>
              <label className={styles.label}>Ціна зі знижкою:</label>
              <input
                value={product?.discountPrice}
                onChange={(e) =>
                  setProduct({ ...product, discountPrice: e.target.value })
                }
              />
              <label className={styles.label}>Початок:</label>
              <input
                type="date"
                value={product?.discountPeriod?.start}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    discountPeriod: {
                      ...product.discountPeriod,
                      start: e.target.value,
                    },
                  })
                }
              />
              <label className={styles.label}>Кінець:</label>
              <input
                type="date"
                value={product?.discountPeriod?.end}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    discountPeriod: {
                      ...product.discountPeriod,
                      end: e.target.value,
                    },
                  })
                }
              />
            </>
          )}

          <div className={styles.checkboxGroup}>
            <input
              type="checkbox"
              checked={isWholeSale}
              onChange={() => {
                setIsDiscount(false);
                setIsWholesale(!isWholeSale);
                setProduct({
                  ...product,
                  discountPrice: null,
                  discountPeriod: { start: null, end: null },
                });
              }}
            />
            <span>Оптові ціни</span>
          </div>

          {isWholeSale && (
            <>
              <label className={styles.label}>Оптові ціни:</label>
              <textarea
                rows={5}
                value={wholesalePrices}
                onChange={(e) => setWholesalePrices(e.target.value)}
              ></textarea>
            </>
          )}

          <label className={styles.label}>Ціна:</label>
          <input
            value={product.price}
            onChange={(e) => setProduct({ ...product, price: e.target.value })}
          ></input>

          <span className={styles.error}>{error}</span>
          <div className={styles.buttons}>
            <button
              onClick={async () => {
                const wholesalePricesArr = [];
                let error = "";
                if (!+product.price) error = "Неправильна ціна";
                else if (isWholeSale) {
                  wholesalePrices.split("\n").forEach((entity) => {
                    const [count, price] = entity.split(" - ");
                    if (Number.isInteger(+count) && Number.isInteger(+price)) {
                      wholesalePricesArr.push({ count: +count, price: +price });
                    } else error = "Неправильний синтаксис оптових цін";
                  });
                } else if (isDiscount) {
                  if (!+product?.discountPrice)
                    error = "Неправильна ціна зі знижкою";
                  else if (!isPeriodValid())
                    error = "Неправильний період знижки";
                }
                if (error) {
                  setError(error);
                  return;
                }
                const formData = new FormData();
                formData.append("title", product.title);
                formData.append("imageURL", product.imageURL);
                formData.append("price", product.price);
                formData.append(
                  "discountPrice",
                  product?.discountPrice ? product?.discountPrice : ""
                );
                formData.append(
                  "wholesalePrices",
                  JSON.stringify(wholesalePricesArr)
                );
                formData.append(
                  "discountPeriod",
                  JSON.stringify(product?.discountPeriod)
                );
                formData.append("isAvailable", product.isAvailable);
                formData.append("toOrder", JSON.stringify(product.toOrder));
                formData.append("recommended", product.recommended);
                formData.append("code", product.code);
                formData.append("flag", product.flag);
                formData.append("file", file);
                formData.append("category", product?.category?.title || "");
                formData.append(
                  "relatedProducts",
                  JSON.stringify(
                    relatedProducts.map((relatedProduct) => relatedProduct._id)
                  )
                );
                try {
                  await adminAPI.editProductPreview(_id, formData);
                  getProduct();
                  if (window.history.length > 1) window.history.back();
                  else window.close();
                } catch (err) {
                  console.log(err);
                }
              }}
              className={styles.saveButton}
            >
              Зберегти
            </button>
            <button
              onClick={() => {
                if (window.history.length > 1) window.history.back();
                else window.close();
              }}
              className={styles.cancelButton}
            >
              Скасувати
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProductPreview;
