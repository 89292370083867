import React from "react";
import styles from "../../styles/admin/SearchInputAdmin.module.css";

const SearchInputAdmin = ({
  setInputValue = () => {},
  setCurrentPage = () => {},
  inputValue = "",
}) => {
  return (
    <div className={styles.searchContainer}>
      <input
        placeholder=""
        className={styles.input}
        onInput={(e) => {
          setInputValue(e.target.value);
          setCurrentPage(1);
        }}
        value={inputValue}
      />
    </div>
  );
};

export const SearchResultsAdmin = ({
  filter = [],
  setFilter = () => {},
  setValue = () => {},
  gap = "50px",
}) => {
  return (
    <>
      {filter.length > 0 && (
        <div
          className={styles.resultsContainer}
          style={{ top: gap }}
        >
          {filter.slice(0, 10).map((text, index) => (
            <span
              key={index}
              onClick={() => setValue(text)}
              className={styles.resultItem}
            >
              {text}
            </span>
          ))}
        </div>
      )}
    </>
  );
};

export default SearchInputAdmin;
