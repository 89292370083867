import React, { useEffect, useRef, useState } from "react";
import Caption from "../caption";
import { adminAPI } from "../../api/api";
import styles from "../../styles/admin/ReturnPolicyAdmin.module.css";

const ReturnPolicyAdmin = ({
  returnPolicy = "",
  productDescription = false,
  editOn = false,
  setEditOn = () => {},
  getInfo = () => {},
}) => {
  const [text, setText] = useState(returnPolicy);

  useEffect(() => {
    setText(returnPolicy);
  }, [returnPolicy]);

  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  }, [text, editOn]);

  if (editOn)
    return (
      <div className={styles.editContainer}>
        <textarea
          value={text}
          ref={textAreaRef}
          onChange={(e) => setText(e.target.value)}
          className={styles.textarea}
        />
        <div className={styles.buttonGroup}>
          <button
            onClick={async () => {
              await adminAPI.editInfo({
                returnPolicy: text,
              });
              getInfo();
              setEditOn(false);
            }}
            className={styles.button}
          >
            Зберегти
          </button>
          <button onClick={() => setEditOn(false)} className={styles.button}>
            Скасувати
          </button>
        </div>
      </div>
    );

  return (
    <div className={styles.container}>
      <Caption text={"Повернення та обмін"} />
      {returnPolicy.split("\n\n").map((block, index) => (
        <div key={`block-${index}`} className={styles.policyBlock}>
          {block.split("\n").map((substring, subIndex) => (
            <span
              key={`text-${index}-${subIndex}`}
              className={
                productDescription && index === 0
                  ? styles.policyTextBold
                  : styles.policyText
              }
            >
              {substring}
            </span>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ReturnPolicyAdmin;
